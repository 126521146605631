/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Andrisam
==============================================================================================*/
@import "sass-lib"; 

@media all and (max-width: 1919px) {

	header {
		height: 82px; 
		.logo {
			width: 123px; height: auto; padding: 16px 0 0 0;        
		}
		.menu-top {
			margin-bottom: 5px;
			a {
				height: 30px; line-height: 30px; padding: 0 20px; 
			}
			.lang {
				.act {
					padding-top: 5px;
					&:after {top: 1px;}
				}
				ul {
					li {
						a {padding: 0 18px !important; line-height: 26px;}
					}
				}
			}
		}   
		nav { 
			ul {
				li {
					a { 
						padding: 0 20px; height: 43px; line-height: 43px;  
						&:after {bottom: -3px;}       
					}
					ul {
						li {
							a {
								height: auto; line-height: 15px;
							}
						}
					}
				}
			}
		}
		&.float { 
			height: 82px; 
			.logo {
				width: 123px; height: auto; padding: 16px 0 0 0;         
			}
			.menu-top {
				margin-bottom: 5px;  
				.lang {
					.act {
						padding-top: 5px;
						&:after {top: 1px;}
					}
				} 
				a {
					height: 30px; line-height: 30px; padding: 0 20px; font-size: 13px;       
					&:last-child {padding:0 20px;}    
				}
			}
			nav {
				ul {
					li {
						a {
							padding: 0 20px; font-size: 16px;   
							height: 43px; line-height: 43px;
						}
						ul {
							li {
								a {
									height: auto; line-height: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.col-box { 
		h2 {}
		h3 {
			font-size: 30px;
			&.v2 {font-size: 26px; line-height: 38px;}  
		}   
	}

	.homeslider {
		.caption {
			.title {
				/*font-size: 28px; line-height: 38px; */
				margin-bottom: 15px;    
			}
		}
		.img {
			height: 450px; 
			img {
				position: relative; object-fit: cover; width: 100%; height: 100%; 
			}
		}  
	}
	.slick-arrow {width: 70px;}
	.home-about {
		.container {padding: 88px 0 73px 0;}  
		.text {
			ol {
				li {font-size: 15px; margin: 0 0 10px 0;}   
			}
		}
	}

	.listing-news {
		.container {
			padding: 90px 0;  
		}
		.row {
			.col {
				.inn {
					padding: 50px 20px 20px 20px; 
					.title {font-size: 24px; line-height: 30px;}    
				}
				&.has-img {
					.inn {
						padding: 15px 20px 20px 20px;   
						.title {font-size: 14px; line-height: 20px;}   
					}
				}
			}
		}
		&.v2 {
			.row {
				.col {
					.inn {padding: 20px 15px 15px 15px;}      
					&.abs-img {
						.inn {
							padding: 50px 15px 15px 15px;    
						}
					}
				}
			}
		}
	}
	.box-news {
		.row {
			.col {
				&.large {
					a {
						&:before {top:40%; width: 88px; height: 66px; background-size: cover !important;}    
					}
					.text {
						.title {font-size: 30px; line-height: 35px;} 
					}
				}
			}
		}
	}
	.home-testi {		
		.container {
			padding: 90px 0 75px 0; min-height: 620px;  
			&:after {}  
		}
		/* .txt-testi {
			.ts-title {font-size: 20px;}
			.ts-des {font-size: 14px; line-height: 20px;}   
			.ts-by {font-size: 13px;}  
		} */
	}
	.home-cta { 
		.bgimg.v2 {background-position: -230px 0 !important;}    
	}

	.middle { 
		padding-top: 82px;     
	}
	
	.box-title {  
		min-height: 330px;     
		.container {padding: 64px 0 37px 0;}    
		h1 {font-size: 34px; line-height: 40px;}        
		&.v2 {
			margin-bottom: 65px;   
			.container {
				padding: 94px 0 37px 0; 
			}
		}             
	}
	.mid-content {  
		.banner-slider {  
			margin: -270px 0 90px 0;   
			.caption {}			
			&.v5 { 
				margin-top: -250px;  
			}			
		}  
		.banner-testimoni {  
			margin: -270px 0 90px 0;   						
		} 
		.box-landing { 
			.row {
				.col {
					&.txt {width: 53.55%; padding: 80px 50px 0 0;}       
					&.img {width: 46.45%;}   
					.ld-des {
						.ct {font-size: 16px; line-height: 30px; padding: 0 0 0 0;}         
					}
					.land-banner {
						.caption {
							&.v2 {font-size: 24px; line-height: 30px;} 
						}
					}
				}
				&:nth-child(even) {
					.col {
						&.txt {padding: 80px 0 0 50px;}           
					}
				}
				&:last-child {margin-bottom: 30px;}         
			}
		}
		.description {
			padding: 0;
			h3 {
				font-size: 38px; line-height: 45px;  
				&.v2 {margin-bottom: 20px;}   
			}
			.box-visi {
				&:before {display:none;}  
				.col {
					width: 100%; padding: 0;
					&:nth-child(2n+2) {padding: 0;}  
				}
			}
			.photo-profile {   
				.col {
					&.text {
						padding: 40px 0 0 40px;  
						h3 {margin-bottom: 2px; font-size: 26px;}        
						h4 {margin-bottom: 15px; font-size: 14px;}           
					}  
				}
			}
			table {
				thead {
					tr {
						th {font-size: 16px; padding: 14px 10px;}   
					}
				}
				tbody {
					tr {
						td {font-size: 14px; padding: 14px 10px;}   
					}
				}
			}
			&.nostyled-table {
				table {width: 60%;}  
			}
			.col-bottom {
				.row {
					.cl {
						&.fl {width: 40%;}
						&.fr {width: 60%;} 
					}
				}
			}
			.testi {
				.photo-profile {padding-bottom: 20px;}
			}
		}

		.grid-category {
			.row {
				.col {
					.caption {font-size: 26px; line-height: 30px; padding: 0 25px;}     
					.img {
						img {width: 100%; height: 100%; background-size: cover;}
					}
					&.first {
						.img {} 
						.caption {
							font-size: 20px; padding: 0 30px;    
							.des {font-size: 14px; line-height: 20px;}         
						}
					}         
				}
			}
			&.col_3 {
				.row {
					.col {
						.caption {
							font-size: 18px; line-height: 22px; 
							.des {font-size: 14px; line-height: 16px;}   
						}
					}
				}
			}
		}
		.col-university {
			.row {
				.col {
					div a {width: 20%; padding: 0 30px 0 0;}   
				}
			}
		}
	}
	.listing-headschool {
		.row {
			.abs {top: 35%;}  
		}
	}
	.tab-container {
		.nav-tab {
			ul {
				li {
					a {padding: 15px 18px 15px 18px;}   
				}
			}
		}
	}

	#boxPop {
		min-width: 800px; max-width: 800px; min-height: 400px;     
		.content_pop {
			.detTxt {
				padding: 20px 0;
				.detCate {padding: 0 0 10px 0;} 
				.detTitle {font-size: 30px;}
				.detDes {font-size: 14px; line-height: 20px; padding: 10px 0 0 0;} 
			} 
			.video-pop {min-height: 400px;}  
			.vid {
	            height:430px; 	           
	        }  
		}
		.popArrow {
			&.prev {left: 0px;} 
			&.next {right: 0px;} 
		}
	}
	#boxPop-2 {
		min-width: 800px; max-width: 800px; min-height: 400px;     
		.content_pop {
			.video-pop {min-height: 400px;}    
		}
	}

	.col-form {
		min-height: 670px;  
		.container {padding:75px 40px;}     
		.form-std {padding: 45px 0 0 0;}    
		&.v2 {
			h3 {font-size: 28px; line-height: 32px;}     
			h4 {font-size: 18px;} 
		} 
	}

	.careermap { 
		.map-location {} 
		.point-map {
			height: 100px;
			left: 0; margin: 0; padding: 0 150px;  
			@include boxSizing(border-box); 
			a {
				&.jkt {left: 150px;}
				&.bali {right: 385px; top: 100px;}  
			}
 		} 
		.des-map {
			left: 0; 
			.title {font-size: 40px; line-height: 50px; padding: 30px 100px 20px 100px;}     
			p {word-break: break-all;}
		}  
		.middle {
			min-height: 768px; max-height: 768px; background-size:auto 100% !important;   
		}	
	}

	.wrap-tankyou {
		min-height: 550px; 
		.container {padding: 80px 0 0px 0;} 
	} 

	
}